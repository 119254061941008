<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  licenseRestore, //恢复门店
  licenseList, //门店分页列表
  licenseDelete, //删除门店
} from "@/api/shop/base/license";
export default {
  name: "license",
  components: { TablePage },
  data() {
    return {
      options: {
        radioSelect: true,
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "门店",
        getListApi: licenseList,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "shopId",
        uuid: "f46e5917-9e73-40cf-4030-8bcc1c7b2c94",
        //搜索
        search: [
          {
            type: "filters",
            tip: "分店编号/分店名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "分店编号" },
              { filter: "shopNames", label: "分店名称" },
            ],
          },
          {
            type: "button",
            tip: "查询",
            btnType: "primary",
            click: "search",
            isRestore: true,
          },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            alertText: "确认要删除选中的门店吗？",
            btnType: "dropdown",
            trigger: "click", // 要求
            other: [
              {
                label: "回收站",
                collectionDialog: {
                  restoreListApi: licenseRestore,
                  dcollectionBody: {
                    delFlag: 1,
                  },
                },
              },
            ],
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "shopNo",
            label: "分店编号",
            type: "link",
            click: "update",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "shopName",
            label: "分店名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "shopAdtress",
            label: "门店地址",
            minWidth: 180,
            align: "center",
          },
          {
            prop: "longitude",
            label: "门店经度",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "latitude",
            label: "门店纬度",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
            align: "center",
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "add":
          //跳转商品明细页
          this.$router.push({
            name: "LicenseDetail",
            query: {
              type: "add",
            },
          });
          break;
        case "update":
          this.$router.push({
            name: "LicenseDetail",
            query: {
              shopId: row.shopId,
              type: "Update",
            },
          });
          break;
        case "del":
          try {
            this.options.loading = true;
            await licenseDelete([this.options.radioObject.shopId]);
            this.$refs.tablePage.getList();
            this.$message.success("删除成功");
          } catch (error) {
          } finally {
            this.options.loading = false;
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
